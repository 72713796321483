<template>
	<Container
		group-name="leadkanbanlist"
		v-on:drop="onDrop(indexValue, rows, $event, statusData.value)"
		:get-child-payload="(e) => getChildPayload(rows, indexValue, e)"
		:drop-placeholder="dropPlaceholderOptions"
		class="overflow-list-item"
		:id="!rows.length ? 'blank-list' : ''"
		:lock-axis="statusData.value == 'completed' || statusData.value == 'cancelled' ? 'y' : undefined"
	>
		<Draggable v-model="statusData.value" v-for="(data, index) in rows" :key="index">
			<div
				:class="`${statusData.status_color} lighten-5 list-items`"
				style="border: 1px solid #00000010 !important"
				v-on:click="pushToRoute(data.id)"
			>
				<div class="d-flex">
					<div class="ellipsis fw-500 mr-2">
						<template v-if="data && data.barcode">
							<v-chip class="text-capitalize mr-2" color="blue white--text" x-small outlined label>
								{{ data.barcode }}
							</v-chip>
						</template>
					</div>
					<v-spacer></v-spacer>
					<div class="ellipsis fw-500 text-end">
						<template v-if="data && data.related_to">
							<span class="fw-500">
								<v-chip
									class="ml-2 text-capitalize"
									color="red white--text"
									x-small
									label
									v-if="data && data.related_to"
									>{{ data.related_to.replace("-", " ") }}</v-chip
								></span
							>
						</template>
					</div>
				</div>

				<div class="ellipsis pr-6" style="font-size: 16px">
					<span class="fw-600 pr-2">Title : </span>
					<template v-if="data && data.title">
						<span class="fw-500">{{ data.title }}</span>
					</template>
				</div>
				<!-- <div class="ellipsis pr-6" v-if="data && data.lead_barcode && data.related_to == 'lead'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Lead # : </span>
						<template v-if="data && data.lead_barcode">
							<span class="fw-500">{{ data.lead_barcode }}</span>
						</template>
					</div>
				</div> -->

				<!-- <div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'lead'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Company Name : </span>
						<template v-if="data && data.related">
							<span class="fw-500">{{ data.related }}</span>
						</template>
						<em class="text-muted" v-else> no company name </em>
					</div>
				</div> -->
				<!-- <div class="ellipsis pr-6" v-if="data && data.task_barcode && data.related_to == 'task'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Task # : </span>
						<template v-if="data && data.task_barcode">
							<span class="fw-500">{{ data.task_barcode }}</span>
						</template>
					</div>
				</div> -->

				<!-- <div class="ellipsis pr-6" v-if="data && data.task_title && data.related_to == 'task'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Task Title : </span>
						<template v-if="data && data.task_title">
							<span class="fw-500">{{ data.task_title }}</span>
						</template>
						<em class="text-muted" v-else> no company name </em>
					</div>
				</div> -->
				<!-- <div class="ellipsis pr-6" v-if="data && data.lead_customer_name && data.related_to == 'lead'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Person Incharge : </span>
						<template v-if="data && data.lead_customer_name">
							<span class="fw-500">{{ data.lead_customer_name }}</span>
						</template>
						<em class="text-muted" v-else> no person incharge </em>
					</div>
				</div> -->
				<!-- <div class="ellipsis pr-6" v-if="data && data.lead_date && data.related_to == 'lead'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Lead Date : </span>
						<template v-if="data && data.lead_date">
							<span class="fw-500">{{ formatDate(data.lead_date) }}</span>
						</template>
						<em class="text-muted" v-else> no lead date </em>
					</div>
				</div> -->
				<!-- <div
					class="ellipsis pr-6"
					v-if="data && data.customer_barcode && data.related_to == 'customer'"
				>
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Customer # : </span>
						<template v-if="data && data.customer_barcode">
							<span class="fw-500">{{ data.customer_barcode }}</span>
						</template>
					</div>
				</div>
				<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'customer'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Company Name : </span>
						<template v-if="data && data.related">
							<span class="fw-500">{{ data.related }}</span>
						</template>
						<em class="text-muted" v-else> no company name </em>
					</div>
				</div>
				<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'customer'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Person Incharge : </span>
						<template v-if="data && data.contacts && data.contacts[0] && data.contacts[0].name">
							<span class="fw-500">{{ data.contacts[0].name }}</span>
						</template>
						<em class="text-muted" v-else> no person incharge </em>
					</div>
				</div> -->
				<!-- <div class="ellipsis pr-6" v-if="data && data.lead_customer_name && data.related_to == 'lead'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Person Incharge : </span>
						<template v-if="data && data.lead_customer_name">
							<span class="fw-500">{{ data.lead_customer_name }}</span>
						</template>
						<em class="text-muted" v-else> no person incharge </em>
					</div>
				</div> -->
				<!-- <div class="ellipsis pr-6" v-if="data && data.lead_date && data.related_to == 'lead'">
					<div class="ellipsis pr-6">
						<span class="fw-600 pr-2">Lead Date : </span>
						<template v-if="data && data.lead_date">
							<span class="fw-500">{{ formatDate(data.lead_date) }}</span>
						</template>
						<em class="text-muted" v-else> no lead date </em>
					</div>
				</div> -->
				<div v class="ellipsis pr-6">
					<span class="fw-600 pr-2">Date : </span>
					<template v-if="data && data.start_from">
						<span class="fw-500"
							>{{ formatDate(data.start_from) }} {{ data.start_time }} ({{ data.duration }})</span
						>
					</template>
				</div>
				<div class="ellipsis pr-6">
					<span class="fw-600 pr-2">Meeting Type : </span>
					<!-- <template v-if="data && data.meeting_type == 'zoom'">
						<v-chip
							class="ml-2 text-capitalize mr-2"
							color="blue white--text"
							x-small
							outlined
							label
							v-if="data && data.meeting_type"
							>{{ data.meeting_type }}
						</v-chip>
						<a :href="data.host_url" target="_blank" class="mr-1" rel="noopener noreferrer"
							>Meeting URL</a
						>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<i class="fa-solid fa-clock-rotate-left"></i>
									<v-icon v-on:click.prevent.stop="copyNumber(data.host_url)" class="mdi-16px ml-1"
										>mdi-content-copy</v-icon
									>
								</span>
							</template>
							<span>Copy URL</span>
						</v-tooltip>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<i class="fa-solid fa-clock-rotate-left"></i>
									<v-icon v-on:click.prevent.stop="sendMailData(data)" class="mdi-16px ml-1"
										>mdi-share-variant</v-icon
									>
								</span>
							</template>
							<span>Share</span>
						</v-tooltip>
					</template> -->
					<template v-if="data && data.meeting_type == 'bthrust'">
						<v-chip
							class="ml-2 text-capitalize"
							color="red white--text"
							x-small
							outlined
							label
							v-if="data && data.meeting_type"
							>F2F Bthrust
						</v-chip>
					</template>
					<template v-if="data && data.meeting_type == 'client'">
						<v-chip
							class="ml-2 text-capitalize"
							color="green white--text"
							x-small
							label
							outlined
							v-if="data && data.meeting_type"
							>F2F Client
						</v-chip>
					</template>
					<template v-if="data && data.meeting_type == 'other'">
						<v-chip
							class="ml-2 text-capitalize"
							color="orange white--text"
							x-small
							outlined
							label
							v-if="data && data.meeting_type"
							>Zoom
						</v-chip>
					</template>
					<template v-if="data && data.meeting_type == 'other2'">
						<v-chip
							class="ml-2 text-capitalize"
							color="orange white--text"
							x-small
							outlined
							label
							v-if="data && data.meeting_type"
							>Other
						</v-chip>
					</template>
				</div>
				<!-- <div class="ellipsis pr-6">
						<span class="fw-600 pr-2">End Date : </span>
						<template v-if="data && data.end_to">
							<span class="fw-500">{{ formatDate(data.end_to) }} </span>
						</template>
					</div> -->
				<!-- <div class="ellipsis pr-6" v-if="data && data.meeting_type != 'zoom'">
					<span class="fw-600 pr-2">Location / URL : </span>
					<template v-if="data && data.location">
						<span class="fw-500 text-capitalize">{{ data.location }}</span>
					</template>
					<template v-else>
						<span class="fw-500 text-capitalize"
							>{{ data.address1 }}, {{ data.address2 }}, {{ data.zip_code }}, {{ data.country }}</span
						>
					</template>
				</div> -->
				<div class="ellipsis pr-6 py-1">
					<span class="fw-600 pr-2">Attendees:</span>
					<template v-if="data.attendies && data.attendies.length">
						<template v-for="(row, index) in data.attendies">
							<span v-if="index < 3" :key="index + 'avatar'">
								<v-tooltip top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-avatar
											v-on="on"
											v-bind="attrs"
											size="30"
											class="white"
											:class="{
												'ml-n3': index,
											}"
											style="border: 1px solid grey !important"
										>
											<v-img v-if="row.image" :src="row.image"></v-img>
											<span v-else class="text-uppercase">{{
												row.display_name && row.display_name.length > 1 ? row.display_name.slice(0, 2) : ""
											}}</span>
										</v-avatar>
									</template>
									<span>{{ row.display_name }}</span>
								</v-tooltip>
							</span>
							<span v-if="index == 3" :key="index + 'more'">
								<v-tooltip top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-avatar
											v-on="on"
											v-bind="attrs"
											size="30"
											style="border: 1px solid grey !important"
											class="ml-n3 white"
										>
											<span> + {{ data.attendies.length - 3 }} </span>
										</v-avatar>
									</template>
									<span>{{ data.attendies.length - 3 }} More</span>
								</v-tooltip>
							</span>
						</template>
					</template>
					<em v-else>no attendies</em>
					<!-- <template
						v-if="data && data.attendies[0] && data.attendies[0].display_name && data.attendies.length"
					>
						<span class="fw-500">
							({{ data.attendies[0].display_name }})
							<template v-if="data.attendies.length > 1">
								+{{ data.attendies.length - 1 }} More
							</template>
						</span>
					</template> -->
				</div>
				<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
			</div>
		</Draggable>
		<template v-if="total_page > current_page">
			<v-btn
				depressed
				tile
				block
				:color="`${statusData ? statusData.status_color : ''}`"
				class="text-white float-right mb-4 d-block"
				v-on:click="getPaginationData(current_page, statusData.text)"
				:disabled="pageLoading"
				:loading="pageLoading"
			>
				<v-icon left> mdi-eye </v-icon>
				Load More
			</v-btn>
		</template>
	</Container>
</template>
<style>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}
.kanban-view-list .kanban-innerlist .v-input--selection-controls.v-input {
	position: absolute;
	right: -8px;
	top: 50px;
}
.v-input--selection-controls {
	margin-top: 4px;
	padding-top: 4px;
	margin-right: 30px;
}
</style>
<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import { cloneDeep, concat, find } from "lodash";
//import Chip from "@/view/components/Chip";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "LeadKanbanList",
	data() {
		return {
			paginatedStatus: null,
			rows: [],
			total_page: 0,
			current_page: 1,
			statusData: {},
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			reminder_create: false,
			pageLoading: false,
			icon: "mdi-plus",
		};
	},
	props: {
		refreshCounts: {
			type: Array,
			default() {
				return [];
			},
		},
		indexValue: {
			type: Number,
			default() {
				return 0;
			},
		},
		leadDetails: {
			type: Object,
			default() {
				return {};
			},
		},
		paginationEndPoint: {
			type: String,
			default() {
				return null;
			},
		},
	},
	watch: {
		leadDetails: {
			deep: true,
			handler(param) {
				const _this = this;
				_this.$nextTick(() => {
					_this.init(param);
				});
			},
		},
	},

	methods: {
		init(param) {
			console.log(this.paginatedStatus);
			if (!this.paginatedStatus) {
				this.rows = param.list;
				this.statusData = param.status;
				this.total_page = param.total_page;
				this.current_page = param.current_page;
			}
		},
		getPaginationData(current_page, status) {
			this.pageLoading = true;
			const { query } = this.$route;
			this.$store
				.dispatch(QUERY, {
					url: this.paginationEndPoint,
					data: {
						...query,
						status: status,
						current_page: current_page + 1,
					},
				})
				.then((data) => {
					let updatedData = find(data, (row) => row.status.text == status);
					if (updatedData) {
						let newListData = updatedData.list;
						let preListData = cloneDeep(this.rows);
						this.rows = concat(preListData, newListData);

						this.total_page = updatedData.total_page;
						this.current_page = updatedData.current_page;
						let newList = updatedData;
						newList.list = this.rows;
						let counting = newList.list.length;
						let statusV = updatedData.status.value;
						//console.log(statusV);
						this.paginatedStatus = statusV;
						let both = { statusV: statusV, counting: counting };
						//let listNew = [newList];
						//console.log(listNew);
						this.$emit("refresh-counts", both);
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		leadPinned(id, status, type, data) {
			this.$store
				.dispatch(PATCH, {
					url: `pin-lead/${id}`,
					data: {
						value: data,
						type: type,
						status: status,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Lead Pinned successfully." },
					]);
					this.getClaimListing();
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},

		update_lead(id) {
			this.$emit("update:lead", id);
		},

		pushToRoute(id) {
			this.$router.push({
				name: "meeting-detail",
				params: { id },
				query: { t: new Date().getTime(), viewType: true },
			});
		},

		openReminder(data) {
			this.$emit("open-reminder", data);
		},

		openRemark(data) {
			this.$emit("open-remark", data);
		},

		onDrop(index, collection, dropResult, dropStatus) {
			let values = { index, collection, dropResult, dropStatus };
			this.$emit("drop", values);
		},

		getChildPayload(row, index, event) {
			let values = { row, index, event };
			this.$emit("child-payload", values);
		},
	},
	components: {
		Container,
		Draggable,
		//Chip,
	},
	mounted() {
		this.$nextTick(() => {
			this.init(this.leadDetails);
		});
		//console.log(this.leadDetails);
	},
};
</script>
