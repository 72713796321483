<template>
	<div class="meeting-kanban" v-if="getPermission('meeting:view')">
		<div class="kanban-view-list">
			<v-flex
				v-for="(row, index) in meetingData"
				:key="index"
				class="kanban-innerlist"
				style="width: calc(25% - 20px)"
			>
				<div :class="`${row.status.status_color} lighten-4 list-items`">
					<div class="d-flex align-center">
						<div class="fw-600 title-text text-h6">{{ row.status.text }}</div>
						<v-chip class="ml-3" :color="`${row.status.status_color} white--text`">
							<span class="text-h6">
								<!-- {{ row.list.length }}  getStatusCounts(row.status.value)-->
								{{ statusCounts[row.status.value] }}
							</span>
						</v-chip>
						<v-spacer></v-spacer>
						<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
					</div>
					<!-- <div class="mt-1" v-if="row.status && row.status.status_color">
						<v-chip class="mb-1 mr-1" label :color="`${row.status.status_color} white--text`" x-small>
							{{ row.list.length }} meeting
						</v-chip>
					</div> -->
				</div>
				<Container
					v-if="false"
					group-name="leadkanbanlist"
					v-on:drop="onDrop(index, row.list, $event, row.status.value)"
					:get-child-payload="(e) => getChildPayload(row.list, index, e)"
					:drop-placeholder="dropPlaceholderOptions"
					class="overflow-list-item"
					:id="!row.list.length ? 'blank-list' : ''"
				>
					<Draggable v-model="row.status.value" v-for="(data, index) in row.list" :key="index">
						<div
							:class="`${row.status.status_color} lighten-5 list-items`"
							style="border: 1px solid #00000010 !important"
							v-on:click="pushToRoute(data.id)"
						>
							<div class="d-flex">
								<div class="ellipsis fw-500 mr-2">
									<template v-if="data && data.barcode">
										<v-chip class="text-capitalize mr-2" color="blue white--text" x-small outlined label>
											{{ data.barcode }}
										</v-chip>
									</template>
								</div>
								<v-spacer></v-spacer>
								<div class="ellipsis fw-500 text-end">
									<template v-if="data && data.related_to">
										<span class="fw-500">
											<v-chip
												class="ml-2 text-capitalize"
												color="red white--text"
												x-small
												label
												v-if="data && data.related_to"
												>{{ data.related_to.replace("-", " ") }}</v-chip
											></span
										>
									</template>
								</div>
							</div>

							<div class="ellipsis pr-6" style="font-size: 16px">
								<span class="fw-600 pr-2">Title : </span>
								<template v-if="data && data.title">
									<span class="fw-500">{{ data.title }}</span>
								</template>
							</div>
							<!-- <div class="ellipsis pr-6" v-if="data && data.lead_barcode && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Lead # : </span>
									<template v-if="data && data.lead_barcode">
										<span class="fw-500">{{ data.lead_barcode }}</span>
									</template>
								</div>
							</div> -->

							<!-- <div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Company Name : </span>
									<template v-if="data && data.related">
										<span class="fw-500">{{ data.related }}</span>
									</template>
									<em class="text-muted" v-else> no company name </em>
								</div>
							</div> -->
							<!-- <div class="ellipsis pr-6" v-if="data && data.task_barcode && data.related_to == 'task'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Task # : </span>
									<template v-if="data && data.task_barcode">
										<span class="fw-500">{{ data.task_barcode }}</span>
									</template>
								</div>
							</div> -->

							<!-- <div class="ellipsis pr-6" v-if="data && data.task_title && data.related_to == 'task'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Task Title : </span>
									<template v-if="data && data.task_title">
										<span class="fw-500">{{ data.task_title }}</span>
									</template>
									<em class="text-muted" v-else> no company name </em>
								</div>
							</div> -->
							<!-- <div class="ellipsis pr-6" v-if="data && data.lead_customer_name && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Person Incharge : </span>
									<template v-if="data && data.lead_customer_name">
										<span class="fw-500">{{ data.lead_customer_name }}</span>
									</template>
									<em class="text-muted" v-else> no person incharge </em>
								</div>
							</div> -->
							<!-- <div class="ellipsis pr-6" v-if="data && data.lead_date && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Lead Date : </span>
									<template v-if="data && data.lead_date">
										<span class="fw-500">{{ formatDate(data.lead_date) }}</span>
									</template>
									<em class="text-muted" v-else> no lead date </em>
								</div>
							</div> -->
							<!-- <div
								class="ellipsis pr-6"
								v-if="data && data.customer_barcode && data.related_to == 'customer'"
							>
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Customer # : </span>
									<template v-if="data && data.customer_barcode">
										<span class="fw-500">{{ data.customer_barcode }}</span>
									</template>
								</div>
							</div>
							<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'customer'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Company Name : </span>
									<template v-if="data && data.related">
										<span class="fw-500">{{ data.related }}</span>
									</template>
									<em class="text-muted" v-else> no company name </em>
								</div>
							</div>
							<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'customer'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Person Incharge : </span>
									<template v-if="data && data.contacts && data.contacts[0] && data.contacts[0].name">
										<span class="fw-500">{{ data.contacts[0].name }}</span>
									</template>
									<em class="text-muted" v-else> no person incharge </em>
								</div>
							</div> -->
							<!-- <div class="ellipsis pr-6" v-if="data && data.lead_customer_name && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Person Incharge : </span>
									<template v-if="data && data.lead_customer_name">
										<span class="fw-500">{{ data.lead_customer_name }}</span>
									</template>
									<em class="text-muted" v-else> no person incharge </em>
								</div>
							</div> -->
							<!-- <div class="ellipsis pr-6" v-if="data && data.lead_date && data.related_to == 'lead'">
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Lead Date : </span>
									<template v-if="data && data.lead_date">
										<span class="fw-500">{{ formatDate(data.lead_date) }}</span>
									</template>
									<em class="text-muted" v-else> no lead date </em>
								</div>
							</div> -->
							<div v class="ellipsis pr-6">
								<span class="fw-600 pr-2">Date : </span>
								<template v-if="data && data.start_from">
									<span class="fw-500"
										>{{ formatDate(data.start_from) }} {{ data.start_time }} ({{ data.duration }})</span
									>
								</template>
							</div>
							<div class="ellipsis pr-6">
								<span class="fw-600 pr-2">Meeting Type : </span>
								<!-- <template v-if="data && data.meeting_type == 'zoom'">
									<v-chip
										class="ml-2 text-capitalize mr-2"
										color="blue white--text"
										x-small
										outlined
										label
										v-if="data && data.meeting_type"
										>{{ data.meeting_type }}
									</v-chip>
									<a :href="data.host_url" target="_blank" class="mr-1" rel="noopener noreferrer"
										>Meeting URL</a
									>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-on="on" v-bind="attrs">
												<i class="fa-solid fa-clock-rotate-left"></i>
												<v-icon v-on:click.prevent.stop="copyNumber(data.host_url)" class="mdi-16px ml-1"
													>mdi-content-copy</v-icon
												>
											</span>
										</template>
										<span>Copy URL</span>
									</v-tooltip>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<span v-on="on" v-bind="attrs">
												<i class="fa-solid fa-clock-rotate-left"></i>
												<v-icon v-on:click.prevent.stop="sendMailData(data)" class="mdi-16px ml-1"
													>mdi-share-variant</v-icon
												>
											</span>
										</template>
										<span>Share</span>
									</v-tooltip>
								</template> -->
								<template v-if="data && data.meeting_type == 'bthrust'">
									<v-chip
										class="ml-2 text-capitalize"
										color="red white--text"
										x-small
										outlined
										label
										v-if="data && data.meeting_type"
										>F2F Bthrust
									</v-chip>
								</template>
								<template v-if="data && data.meeting_type == 'client'">
									<v-chip
										class="ml-2 text-capitalize"
										color="green white--text"
										x-small
										label
										outlined
										v-if="data && data.meeting_type"
										>F2F Client
									</v-chip>
								</template>
								<template v-if="data && data.meeting_type == 'other'">
									<v-chip
										class="ml-2 text-capitalize"
										color="orange white--text"
										x-small
										outlined
										label
										v-if="data && data.meeting_type"
										>Zoom
									</v-chip>
								</template>
								<template v-if="data && data.meeting_type == 'other2'">
									<v-chip
										class="ml-2 text-capitalize"
										color="orange white--text"
										x-small
										outlined
										label
										v-if="data && data.meeting_type"
										>Other
									</v-chip>
								</template>
							</div>
							<!-- <div class="ellipsis pr-6">
									<span class="fw-600 pr-2">End Date : </span>
									<template v-if="data && data.end_to">
										<span class="fw-500">{{ formatDate(data.end_to) }} </span>
									</template>
								</div> -->
							<!-- <div class="ellipsis pr-6" v-if="data && data.meeting_type != 'zoom'">
								<span class="fw-600 pr-2">Location / URL : </span>
								<template v-if="data && data.location">
									<span class="fw-500 text-capitalize">{{ data.location }}</span>
								</template>
								<template v-else>
									<span class="fw-500 text-capitalize"
										>{{ data.address1 }}, {{ data.address2 }}, {{ data.zip_code }}, {{ data.country }}</span
									>
								</template>
							</div> -->
							<div class="ellipsis pr-6 py-1">
								<span class="fw-600 pr-2">Attendees : </span>
								<template v-if="data.attendies && data.attendies.length">
									<template v-for="(row, index) in data.attendies">
										<span v-if="index < 3" :key="index + 'avatar'">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<v-avatar
														v-on="on"
														v-bind="attrs"
														size="30"
														class="white"
														:class="{
															'ml-n3': index,
														}"
														style="border: 1px solid grey !important"
													>
														<v-img v-if="row.image" :src="row.image"></v-img>
														<span v-else class="text-uppercase">{{
															row.display_name && row.display_name.length > 1 ? row.display_name.slice(0, 2) : ""
														}}</span>
													</v-avatar>
												</template>
												<span>{{ row.display_name }}</span>
											</v-tooltip>
										</span>
										<span v-if="index == 3" :key="index + 'more'">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<v-avatar
														v-on="on"
														v-bind="attrs"
														size="30"
														style="border: 1px solid grey !important"
														class="ml-n3 white"
													>
														<span> + {{ data.attendies.length - 3 }} </span>
													</v-avatar>
												</template>
												<span>{{ data.attendies.length - 3 }} More</span>
											</v-tooltip>
										</span>
									</template>
								</template>
								<!-- <template
									v-if="data && data.attendies[0] && data.attendies[0].display_name && data.attendies.length"
								>
									<span class="fw-500">
										({{ data.attendies[0].display_name }})
										<template v-if="data.attendies.length > 1">
											+{{ data.attendies.length - 1 }} More
										</template>
									</span>
								</template> -->
							</div>
							<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
						</div>
					</Draggable>
				</Container>

				<MeetingKanbanList
					v-if="row"
					:key="new Date().getTime()"
					v-on:drop="onDrop"
					v-on:child-payload="getChildPayload"
					:index-value="index"
					:lead-details="row"
					pagination-end-point="meetings-list-kanban"
					v-on:refresh-counts="setStatusCounts($event)"
				/>
			</v-flex>
		</div>

		<template>
			<Dialog :dialog="statusDialog" v-if="statusDialog && false" :dialog-width="640">
				<template v-slot:title>Status Remark</template>
				<template v-slot:body>
					<v-form
						ref="extendCheckoutForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="onSubmit"
					>
						<v-row class="mb-3">
							<v-col md="2" class="py-0">
								<label class="fw-500 mb-2">Status &nbsp; &nbsp;: </label>
							</v-col>
							<v-col md="10" class="py-0">
								<span class="primary--text fw-500 text-capitalize"> {{ statusdata }}</span>
							</v-col> </v-row
						><v-row class="mb-3">
							<v-col md="2" class="py-0">
								<label class="fw-500 mb-2">Remark :</label>
							</v-col>
							<v-col md="10" class="py-0">
								<TextAreaInput
									:rows="2"
									dense
									hide-details
									placeholder="Enter remark..."
									:word-limit="200"
									v-model="status_remark.remark"
								></TextAreaInput>
							</v-col>
						</v-row>
					</v-form>
				</template>
				<template v-slot:action>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="onSubmit()"
					>
						Save
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="closeDialog"> Close </v-btn>
				</template>
			</Dialog>
		</template>

		<MeetingApprovalDialog
			v-if="statusDialog"
			:dialog="statusDialog"
			:action-type="statusDataNew"
			:claim-id="meetingId"
			:claim-data="meetingDetail"
			@close="statusDialog = false"
			@success="getMeetingListing(), callAllListing()"
		/>
	</div>
</template>
<style>
/* .theme--dark.v-overlay.v-overlay--active {
	z-index: 100 !important;
} */
</style>
<script>
import { Container, Draggable } from "vue-smooth-dnd";
import TextAreaInput from "@/view/components/TextAreaInput";
import Dialog from "@/view/components/Dialog";

import { QUERY, POST } from "@/core/services/store/request.module"; //PATCH
import { cloneDeep } from "lodash"; //filter
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { MeetingEventBus } from "@/core/lib/meeting.form.lib";
import MeetingKanbanList from "@/view/components/MeetingKanbanList";

import MeetingApprovalDialog from "@/view/module/meeting/MeetingApprovalDialog.vue";

export default {
	name: "MeetingKanban",
	data() {
		return {
			dataTestt: [],
			statusCounts: {
				upcoming: 0,
				postpone: 0,
				completed: 0,
				cancelled: 0,
			},
			statusDataNew: null,
			meetingDetail: {},
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},

			mailData: {},
			statusvalue: 0,
			statusdata: 0,
			dragstatus: null,
			formValid: true,
			pageLoading: false,
			meetingId: 0,
			viewType: true,
			statusDialog: false,
			status_remark: {
				drop_status: "Cancelled",
				dropData: null,
				remark: null,
				drop_value: 0,
			},
			responseData: [],
			statusDropDown: [
				{ value: "blue", text: "Raised" },
				{ value: "green", text: "Paid" },
				{ value: "red", text: "Unpaid" },
				{ value: "cyan", text: "Postponed" },
				{ value: "red", text: "Cancelled" },
			],
			meetingData: [],
		};
	},
	watch: {
		"$route.query": {
			deep: true,
			immediate: true,
			handler(query) {
				this.getMeetingListing(query);
			},
		},
	},
	methods: {
		// getStatusCounts(status=''){

		// 	let returnCount = 0;
		// 	switch(status){
		// 		case 'upcoming':
		// 			returnCount =  this.statusCounts.upcoming;
		// 		break;
		// 		case 'postpone':
		// 			returnCount =  this.statusCounts.postpone;
		// 		break;
		// 		case 'completed':
		// 			returnCount =  this.statusCounts.completed;
		// 		break;
		// 		case 'cancelled':
		// 			returnCount =  this.statusCounts.cancelled;
		// 		break;
		// 	}
		// 	//console.log(returnCount);
		// 	return returnCount;
		// },

		setStatusCounts(vals) {
			//console.log(vals);

			this.statusCounts[vals.statusV] = vals.counting;

			let status = vals.statusV;
			let total = vals.counting;

			switch (status) {
				case "upcoming":
					this.statusCounts.upcoming = total;
					break;
				case "postpone":
					this.statusCounts.postpone = total;
					break;
				case "completed":
					this.statusCounts.completed = total;
					break;
				case "cancelled":
					this.statusCounts.cancelled = total;
					break;
			}
		},
		refreshAllKanBanStatusCounts(data = []) {
			if (!data) return;

			//console.log(data);
			for (let i = 0; i < data.length; i++) {
				//set statusCounts
				let statusValue = data[i].status.value;
				switch (statusValue) {
					case "upcoming":
						this.statusCounts.upcoming = data[i].list.length;
						break;
					case "postpone":
						this.statusCounts.postpone = data[i].list.length;
						break;
					case "completed":
						this.statusCounts.completed = data[i].list.length;
						break;
					case "cancelled":
						this.statusCounts.cancelled = data[i].list.length;
						break;
				}
			}
		},
		callAllListing() {
			//console.log('test');
			this.$emit("scucess");
		},
		copyNumber(data) {
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		sendMailData(data) {
			this.$emit("mailSend", data);
		},

		closeDialog() {
			this.statusDialog = false;
		},
		pushToRoute(id) {
			this.$router.push({
				name: "meeting-detail",
				params: { id },
				query: { t: new Date().getTime(), viewType: true },
			});
		},
		openStatusDialog(value) {
			this.status_remark = {};
			this.statusDialog = true;
			if (value == "completed") {
				(this.status_remark.drop_status = "Completed"), (this.status_remark.drop_value = 1);
			} else if (value == "cancelled") {
				(this.status_remark.drop_status = "Cancelled"), (this.status_remark.drop_value = 2);
			} else if (value == "overdue") {
				(this.status_remark.drop_status = "Overdue"), (this.status_remark.drop_value = 3);
			} else if (value == "upcoming") {
				(this.status_remark.drop_status = "Upcoming"), (this.status_remark.drop_value = 4);
			}
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.extendCheckoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.extendCheckoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			if (this.statusdata == "completed") {
				this.status_remark.drop_value = 1;
			} else if (this.statusdata == "cancelled") {
				this.status_remark.drop_value = 2;
			} else if (this.statusdata == "overdue") {
				this.status_remark.drop_value = 3;
			} else if (this.statusdata == "upcoming") {
				this.status_remark.drop_value = 4;
			}
			this.status_remark.drop_status = this.statusdata;
			this.$store
				.dispatch(POST, { url: `status-remark/${this.meetingId}`, data: this.status_remark })
				.then(({ data }) => {
					this.responseData = data;
					this.statusDialog = false;
					this.getMeetingListing();
					this.$store.commit(SET_MESSAGE, [{ model: true, message: "Succes updated successfully." }]);

					this.$refs.form.reset();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getMeetingListing(query) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `meetings-list-kanban`,
					data: {
						status: "all",
						withOutPage: true,
						...query,
					},
				})
				.then((data) => {
					this.meetingData = data;

					this.refreshAllKanBanStatusCounts(data);

					// const output = [];
					// for (let i = 0; i < data.status.length; i++) {
					// 	if (data.status[i].value == "all") {
					// 		continue;
					// 	}
					// 	output.push({
					// 		status: data.status[i],
					// 		list: filter(data.tbody, { status: data.status[i].text }),
					// 	});
					// }
					// this.meetingData = output;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getChildPayload(values) {
			//row, index, event
			const { row, index, event } = values;
			this.meetingId = row[event].id;
			this.dragstatus = row[event].status;

			return this.meetingData[index];
		},

		onDrop(values) {
			//console.log(values); //index, collection, dropResult, dropStatus
			const { index, collection, dropResult, dropStatus } = values;
			const { addedIndex } = dropResult;

			if (addedIndex != null) {
				this.statusdata = dropStatus;
			}

			if (this.statusdata == "completed") {
				this.statusDataNew = "mark_as_completed";
			} else if (this.statusdata == "cancelled") {
				this.statusDataNew = "mark_as_cancelled";
			} else if (this.statusdata == "postpone" || this.statusdata == "postponed") {
				this.statusDataNew = "mark_as_postponed";
			}

			console.log(this.dragstatus, this.statusdata); // 3, completed etc.
			if (this.statusdata == 0) {
				//validation when he drag on same column, first time on load
				return false;
			}

			if (this.dragstatus == 3) {
				if (this.dragstatus == 3 && (this.statusdata == "postponed" || this.statusdata == "postpone")) {
					return false; //validation when he drag on same column
				}
				//validate if this dropped to upcoming
				if (this.statusdata == "upcoming") {
					return false;
				}

				this.openStatusDialog(this.statusdata);
				//this.meetingData[index].list = this.applyDrag(collection, dropResult);
				//this.getMeetingListing();
				//this.$emit("scucess");
				this.statusdata = 0;
				return false;
			}

			if (this.dragstatus == 4) {
				if (this.dragstatus == 4 && this.statusdata == "upcoming") {
					return false; //validation when he drag on same column
				}
				this.openStatusDialog(this.statusdata);
				//this.meetingData[index].list = this.applyDrag(collection, dropResult);
				//this.getMeetingListing();
				//this.$emit("scucess");
				this.statusdata = 0;
				return false;
			}

			/*
			if (
				this.dragstatus != "Completed" &&
				this.statusdata != "completed" &&
				this.statusdata != "upcoming"
			) {
				//console.log(this.statusdata, "statusdata");
				this.openStatusDialog(this.statusdata);
				this.meetingData[index].list = this.applyDrag(collection, dropResult);
				this.getMeetingListing();
				return false;
			}

			if (this.statusdata == "upcoming") {
				this.meetingData[index].list = this.applyDrag(collection, dropResult);
				this.getMeetingListing();
				return false;
			}
			if (this.dragstatus == "Completed") {
				this.meetingData[index].list = this.applyDrag(collection, dropResult);
				this.getMeetingListing();
				return false;
			}*/

			/*if (addedIndex != null) {
				this.update_loading = true;
				this.$store
					.dispatch(PATCH, { url: `meeting/${this.meetingId}/${this.statusdata}` })
					.then(() => {
						this.getMeetingListing();
					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {
						this.update_loading = false;
					});
			}*/

			this.meetingData[index].list = this.applyDrag(collection, dropResult);

			//this.$emit("scucess");
		},

		applyDrag(output, dragResult) {
			const { removedIndex, addedIndex, payload } = dragResult;
			if (removedIndex === null && addedIndex === null) return output;
			const result = cloneDeep(output);
			let itemToAdd = payload.list[addedIndex];
			if (removedIndex !== null) {
				itemToAdd = result.splice(removedIndex, 1)[0];
			}
			if (addedIndex !== null) {
				result.splice(addedIndex, 0, itemToAdd);
			}

			return result;
		},
	},
	mounted() {
		this.viewType = this.$route.query.viewType;
		MeetingEventBus.$on("refresh", () => {
			this.getMeetingListing();
		});
	},
	components: {
		Container,
		Draggable,
		Dialog,
		TextAreaInput,
		MeetingKanbanList,
		MeetingApprovalDialog,
	},
};
</script>
